import React from 'react'
import { NavBar, Icon, Toast } from 'antd-mobile'
import Component, { mapStateToProps, mapDispatchToProps } from '../components/Component'
import { connect } from 'react-redux'
import style from './DocumentExchange.module.less'
import { TextList, TextItem, TextTitle, FileTitleItem } from '../components/TextList'
import Border from '../components/Border'
import { formatDate } from '@/assets/js/tool'

class CaseDetail extends Component {
  state = {
    height: 0,
    data: {},
    loading: false,
    persons: []
  }

  status = true
  containerRef = React.createRef()
  navbarRef = React.createRef()

  // 获取数据
  getData () {
    Toast.loading('数据加载中', 120)
    this.setState({ loading: true })
    const id = this.props.match.params.id
    this.$get(`/api/xapc/documentExchange/${id}`)
      .then(res => {
        Toast.hide()
        this.setState({ loading: false })
        if (this.status === false) {
          return false
        }
        if (res.status === 1 && res.data) {
          const d = res.data.document
          this.setState({
            data: res.data,
            persons: d.document_participant instanceof Array ? d.document_participant.map((li, i) => { li.index = i + 1; return li }) : []
          })
        } else {
          Toast.fail(res.message, 2)
        }
      }).catch(() => {
        Toast.hide()
        this.setState({ loading: false })
      })
  }

  // 格式化金钱
  formatMoney (money) {
    const m = String(money)
    if (!/^\d*$/.test(m)) {
      return m
    }
    return m.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  componentDidMount () {
    this.getData()
  }

  componentWillUnmount () {
    this.status = false
  }

  renderDetail () {
    if (this.state.loading) {
      return <div />
    }
    const a = this.state.data
    const doc = a.document && a.document.signed_file instanceof Array ? a.document.signed_file : []
    return (
      <div className={style.detail}>
        <Border left={false} right={false} bottom={false} radius={false}>
          <TextTitle>交互信息</TextTitle>
        </Border>
        <TextList>
          <TextItem title='案号' value={a.caseno_desc || '无'} />
          <TextItem title='类型' value={a.type || '无'} />
          <TextItem title='创建人' value={a.operator || '无'} />
          <TextItem title='状态' value={a.status || '无'} />
          <TextItem title='创建时间' value={formatDate(a.create_datetime)} />
          <TextItem title='文书来源' value={a.filetitle ? '文书模板' : '文件上传'} />
          {a.filetitle && <TextItem title='文书模版' value={a.filetitle} />}
          {a.file instanceof Array && a.file.length > 0 && (
            <FileTitleItem title='上传文件' value={a.file} />
          )}
          {doc.length > 0 && (
            <FileTitleItem title='确认/签名文件' value={doc} />
          )}
        </TextList>
        <Border left={false} right={false} bottom={false} radius={false}>
          <TextTitle>确认/签名人员</TextTitle>
        </Border>
        <div className={style.persons}>
          {
            this.state.persons.map((li, i) => {
              const v = (li.complete_mode || '').toLocaleLowerCase()
              let o = ''
              if (v === 'ca') {
                o = '电子签名'
              } else if (v === 'click') {
                o = '点击确认'
              }
              return (
                <div key={i} className={style['person-line']}>
                  <span className={style.order}>{i + 1}</span>
                  <span className={style.split}>姓名：{li.name}</span>
                  <span className={style.split}>仲裁地位：{li.action_subject}</span>
                  {o && (
                    <span className={style.split}>
                      类型：{o}
                    </span>
                  )}
                  <span className={style.split}>状态{a.status === '审核不通过' ? '不同意' : a.status}</span>
                  <span className={style.split}>时间：{formatDate(li.submit_datetime)}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  render () {
    return (
      <div ref={this.containerRef} className={style.container}>
        <div className={style.header}>
          <NavBar ref={this.navbarRef} mode='light' icon={<Icon type='left' size='lg' />} onLeftClick={() => this.props.history.goBack()}>案件交互</NavBar>
        </div>
        {this.renderDetail()}
      </div>
    )
  }
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(CaseDetail)
