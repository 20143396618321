import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './TextList.module.less'
import Border from './Border'
import { Icon } from 'antd-mobile'
import classNames from 'classnames'

export class TextList extends Component {
  render () {
    return (
      <div className={style.list}>
        <Border left={false} right={false} radius={false}>
          <div className={style['list-inner']}>
            {this.props.children}
          </div>
        </Border>
      </div>
    )
  }
}

export class TextItem extends Component {
  render () {
    return (
      <div className={style.item}>
        <div className={style['item-inner']}>
          <span className={style['item-title']}>{this.props.title}</span>
          &nbsp;
          <span className={style['item-value']}>
            <span className={style['item-text']}>{this.props.value}</span>
          </span>
        </div>
      </div>
    )
  }
}

TextItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
}

TextItem.defaultProps = {
  title: '',
  value: ''
}

export class ContentTitleItem extends Component {
  render () {
    return (
      <div className={style.item}>
        <div className={style['item-inner']}>
          <span className={style['item-title']}>{this.props.title}</span>
        </div>
        <div>
          {this.props.value}
        </div>
      </div>
    )
  }
}

ContentTitleItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
}

ContentTitleItem.defaultProps = {
  title: '',
  value: ''
}

export class FileTitleItem extends Component {
  render () {
    return (
      <div className={style.item}>
        <div className={style['item-inner']}>
          <span className={style['item-title']}>{this.props.title}</span>
        </div>
        <div className={style.files}>
          {
            (this.props.value || []).map((li, i) => {
              return (
                <FileTitleItemLine key={i} title={li.original_filename || li.filename} value={li.path} index={i + 1} />
              )
            })
          }
        </div>
      </div>
    )
  }
}

FileTitleItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.array
}

FileTitleItem.defaultProps = {
  title: '',
  value: ''
}

class FileTitleItemLine extends Component {
  state = {
    active: false
  }

  render () {
    return (
      <div
        className={classNames(style['file-line'], { [style.active]: this.state.active })}
        onClick={() => {
          this.setState({ active: true })
          setTimeout(() => {
            this.setState({ active: false })
            window.open(this.props.value, undefined, '_blank')
          }, 200)
        }}
      >
        <span className={style['file-line-no']}>{this.props.index}</span>
        <span>{this.props.title}</span>
        <span className={style['file-line-arrow']}><Icon type='right' size='lg' /></span>
      </div>
    )
  }
}

FileTitleItemLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number
}

FileTitleItemLine.defaultProps = {
  title: '',
  value: ''
}

export class ContentItem extends Component {
  render () {
    return (
      <div className={style['content-item']} onClick={() => this.props.onClick()}>
        <span className={style['content-item-title-content']}>{this.props.titleContent}</span>
        <span className={style['content-item-value']}>
          <span className={style['item-text']}>{this.props.value}</span>
        </span>
      </div>
    )
  }
}

ContentItem.propTypes = {
  value: PropTypes.any,
  titleContent: PropTypes.node,
  onClick: PropTypes.func
}

ContentItem.defaultProps = {
  value: '',
  titleContent: null,
  onClick: () => {}
}

export class TextTitle extends Component {
  render () {
    return (
      <div className={style.title}>
        <span>{this.props.children}</span>
        <span>{this.props.rightContent}</span>
      </div>
    )
  }
}

TextTitle.propTypes = {
  rightContent: PropTypes.string
}

TextTitle.defaultProps = {
  rightContent: ''
}

export class TextMulti extends Component {
  render () {
    const s = { }
    if (!this.props.bottom) {
      s.marginBottom = 0
    }
    return (
      <div className={style['text-multi']} style={s}>
        {this.props.children}
      </div>
    )
  }
}

TextMulti.propTypes = {
  bottom: PropTypes.bool
}

TextMulti.defaultProps = {
  bottom: true
}
