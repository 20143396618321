import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { TextTitle, TextList, TextItem, ContentTitleItem, FileTitleItem } from '@/components/TextList'
import { formatDate } from '@/assets/js/tool'
import style from './Step.module.less'
import classNames from 'classnames'
import { Icon } from 'antd-mobile'

export default class StepOneHistory extends Component {
  state = {
    keyTitleMap: {},
    keyValueMap: {},
    exchagneList: []
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  initKeyTitleMap () {
    const map = {}
    const vMap = {}
    const list = _.get(this.props.action || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
      vMap[li.key] = li.value
    })
    this.setState({
      keyTitleMap: map,
      keyValueMap: vMap
    })
  }

  initExchangeList (v) {
    if (typeof v === 'string') {
      try {
        this.setState({
          exchagneList: JSON.parse(v)
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  componentDidUpdate (prev) {
    if (this.props.action !== prev.action) {
      this.initKeyTitleMap()
      this.initExchangeList((this.props.action.varsMap.document_exchangeid || {}).value)
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
    this.initExchangeList((this.props.action.varsMap.document_exchangeid || {}).value)
  }

  renderItems () {
    const l = this.state.exchagneList
    return (
      <div>
        {l.map((li, i) => {
          return (
            <Item key={i} data={li} index={i} caseDetail={this.props.caseDetail} history={this.props.history} />
          )
        })}
      </div>
    )
  }

  render () {
    const { keyTitleMap } = this.state
    const l = this.state.exchagneList
    const d = this.props.action
    const files = (this.state.keyValueMap ? this.state.keyValueMap.case_document_name || '' : '').split(',').filter(li => li)
    return (
      <div>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={d.operator} />
          <TextItem title='操作' value={d.action_name} />
          {l.length > 0 && <ContentTitleItem title={keyTitleMap.document_exchangeid} value={this.renderItems()} />}
          {l.length === 0 && <FileTitleItem title={keyTitleMap.case_document_name} value={files} />}
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }
}

StepOneHistory.propTypes = {
  action: PropTypes.object,
  caseDetail: PropTypes.object
}

StepOneHistory.defaultProps = {
  action: {},
  caseDetail: ''
}

function Item ({ data, index, caseDetail, history }) {
  const [active, setActive] = useState(false)
  return (
    <div
      className={classNames(style.item, { [style.active]: active })} onClick={() => {
        setActive(true)
        setTimeout(() => {
          setActive(false)
          history.push(`/admin/approve-detail/${caseDetail.id}/document-exchange/${data.id}`)
        }, 200)
      }}
    >
      <span className={style['item-no']}>{index + 1}</span>
      <span className={style['item-split']}>{data.type}</span>
      <span className={style['item-split']}>{data.operator}</span>
      <span className={style['item-split']}>{formatDate(data.complete_datetime)}</span>
      <span className={style['item-icon']}><Icon type='right' size='md' /></span>
    </div>
  )
}
