// import React from 'react'
import CaseDetail from '../pages/CaseDetail'
import DocumentExchange from '../pages/DocumentExchange'

export default [
  {
    path: '/admin/approve-detail/:id/case-detail/:caseid',
    component: CaseDetail,
    push: 'forward',
    pop: 'back',
    exact: true
  },
  {
    path: '/admin/approve-detail/:caseid/document-exchange/:id',
    component: DocumentExchange,
    push: 'forward',
    pop: 'back',
    exact: true
  }
]
