import React from 'react'
import PropTypes from 'prop-types'
import Component, { mapStateToProps, mapDispatchToProps } from '../Component'
import { connect } from 'react-redux'
import { Toast, List, InputItem, TextareaItem, Button, Modal, ActionSheet, Result, Icon } from 'antd-mobile'
import { createForm, formShape } from 'rc-form'
import _ from 'lodash'
import style from './Index.module.less'
import { TextTitle, TextList, TextItem } from '../TextList'
import moment from 'moment'
import Border from '../Border'
const alert = Modal.alert
class Index extends Component {
  state = {
    history: [],
    ready: false,
    isError: false,
    doActioning: false, // 操作提交中
    currentAction: null, // 当前操作
    currentStep: null, // 当前操作步骤数据
    currentFormData: {}, // 当前操作步骤表单数据
    nextOperatorId: '', // 下一步操作者id
    nextOperators: [], // 下一步操作者列表
    otherData: null
  }

  getVarsMap (vars) {
    const map = {}
    vars.forEach(v => {
      map[v.key] = v
    })
    return map
  }

  // 获取实例的操作历史
  getHistory () {
    return this.$get('/api/xapc/flowinstance/action', {
      params: {
        instanceid: this.props.flowInstanceid
      }
    }).then(res => {
      if (res.status === 1) {
        const history = res.data
        history.sort((a, b) => a.create_datetime - b.create_datetime)
        history.forEach(h => {
          h.varsMap = this.getVarsMap(h.vars)
        })
        return history
      } else {
        throw new Error(res.message)
      }
    })
  }

  // 获取实例的当前操作节点
  getCurrentStep () {
    return this.$get(`/api/xapc/flowinstance/${this.props.flowInstanceid}`)
      .then(res => {
        if (res.status === 1) {
          const currentStep = res.data
          currentStep.varsMap = this.getVarsMap(res.data.vars)
          return currentStep
        } else {
          throw new Error(res.message)
        }
      })
  }

  // 获取呈批数据
  getData () {
    Toast.loading('加载中', 120)
    Promise.all([this.getHistory(), this.getCurrentStep()])
      .then(async (res) => {
        const [history, currentStep] = res
        Toast.hide()
        const otherData = await this.props.initOtherData(history, currentStep)
        this.setState({
          history,
          currentStep,
          otherData
        }, () => {
          if (this.props.initialCurrentStepFormData) {
            const data = this.props.initialCurrentStepFormData(currentStep, otherData)
            setTimeout(() => {
              this.props.form.setFieldsValue(data)
            }, 0)
          }
        })
        this.setState({
          ready: true
        })
      })
      .catch((e) => {
        this.setState({ isError: true, ready: true })
      })
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  // 触发操作
  doAction (action) {
    if (this.state.doActioning) {
      return undefined
    }
    this.setState({
      currentAction: action
    }, () => {
      this.props.form.validateFields(null, (errors, values) => {
        if (!errors) {
          this.dealAction(action, values)
        }
      })
    })
  }

  // 处理触发的操作
  async dealAction (action, formData) {
    const { actions, currentStep, otherData } = this.state
    const instance = { action, actions, currentStep, otherData }
    try {
      formData = await this.props.beforeSubmit(instance, formData)
    } catch (e) {
      console.warn(e)
      return Toast.fail('处理数据失败', 2)
    }
    this.setState({
      currentFormData: formData
    }, () => {
      this.setState({
        nextOperatorId: ''
      })
      if (!action.next_operators || action.next_operator_type !== 'step_some') { // 当下一步没有操作者时，直接提交
        return this.toSubmitAction()
      }
      if (action.next_operators.length === 1) {
        this.setState({
          nextOperatorId: action.next_operators[0].id
        }, () => {
          this.toSubmitAction()
        })
      } else {
        this.setState({
          nextOperators: action.next_operators // 下一步操作者选择列表
        }, () => {
          this.showNextOperatorsSelector()
        })
      }
    })
  }

  // 显示下一步操作人员列表
  showNextOperatorsSelector () {
    const { nextOperators } = this.state
    const BUTTONS = nextOperators.map(li => li.name)
    BUTTONS.push('取消')
    ActionSheet.showActionSheetWithOptions({
      options: BUTTONS,
      cancelButtonIndex: BUTTONS.length - 1,
      message: '请选择下一步操作者',
      maskClosable: true,
      wrapProps: { onTouchStart: e => e.preventDefault() }
    },
    (buttonIndex) => {
      if (buttonIndex < nextOperators.length - 1) {
        this.setState({
          nextOperatorId: nextOperators[buttonIndex].id
        }, () => {
          this.toSubmitAction()
        })
      }
    })
  }

  // 从当前操作步骤中获取vars
  getVarsFromFormData () {
    const { currentStep, currentFormData, currentAction } = this.state
    const vars = _.cloneDeep(currentStep.vars)
    if (currentAction.opt_add === 'disagree') {
      return []
    }
    vars.forEach(v => {
      delete v.options
      const value = (currentFormData.vars || {})[v.key]
      if (v) {
        v.value = value
      } else if (!v.value) {
        v.value = ''
      }
    })
    return vars
  }

  // 提交操作请求
  async toSubmitAction () {
    const { currentAction, nextOperatorId, currentStep, currentFormData, actions } = this.state
    Toast.loading('提交中', 120)
    this.setState({ doActioning: true })
    this.$post('/api/xapc/flowinstance/action', {
      flowid: this.props.flowid,
      flow_instanceid: this.props.flowInstanceid,
      actionid: currentAction.id,
      action_name: currentAction.name,
      stepid: currentStep.stepid,
      remark: currentFormData.remark,
      operatorid_to: nextOperatorId,
      vars: this.getVarsFromFormData()
    }).then(res => {
      setTimeout(() => {
        this.setState({ doActioning: false })
      }, 200)
      Toast.hide()
      if (res.status === 1) {
        Toast.success('提交成功')
        this.props.afterAction({ actions, currentStep }, currentAction) // 操作结束后，执行函数
        this.setState({
          currentAction: null,
          nextOperators: [],
          nextOperatorId: '',
          currentFlow: null,
          currentFormData: {}
        })
      } else {
        Toast.fail(res.message)
      }
    }).catch(() => {
      Toast.hide()
      setTimeout(() => {
        this.setState({ doActioning: false })
      }, 200)
    })
  }

  componentDidMount () {
    this.getData()
  }

  // 渲染历史模块列表
  renderHistoryList () {
    const { history } = this.state
    return history.map(li => {
      return this.props.renderHistory(li, this.renderHistory.bind(this))
    })
  }

  // 渲染操作历史块
  renderHistory (d) {
    return (
      <div key={d.id}>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作' value={d.action_name} />
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }

  // 渲染当前操作步骤
  renderCurrentStep (d) {
    const { getFieldProps } = this.props.form
    const remarkTitle = d.remark_alias || '备注'
    return (
      <List renderHeader={() => d.step_name}>
        <InputItem defaultValue={this.props.userInfo.realname} editable={false}>操作者</InputItem>
        <TextareaItem {...getFieldProps('remark', { rules: [] })} title={remarkTitle} autoHeight clear placeholder={`请输入${remarkTitle}`} />
      </List>
    )
  }

  // 渲染当前操作步骤的操作按钮
  renderOperateBtn () {
    const { currentStep: d, doActioning } = this.state
    if (!d || !d.stepid) {
      return null
    }
    const isOperate = d.operators.some(o => o.id === this.props.userInfo.employeeid)
    if (!isOperate) { // 判断当前操作者是否有操作权限
      return null
    }
    d.actions.sort((a, b) => b.orderno - a.orderno)
    return (
      <div className={style['operate-btns']}>
        {
          d.actions.map(li => {
            let type = 'primary'
            if (li.opt_add === 'disagree') {
              type = ''
            }
            const confirm = (li) => {
              alert('提示', `您确定${li.name}吗？`, [
                { text: '取消' },
                { text: '确定', onPress: () => this.doAction(li) }
              ])
            }
            return <Button disabled={doActioning} type={type} key={li.id} size='midlle' inline onClick={() => confirm(li)}>{li.name}</Button>
          })
        }
      </div>
    )
  }

  // 渲染无权限操作的当前步骤
  noAuthorityrCurrentStep (d) {
    const operatorNames = d.operators.map(li => li.name).join('、')
    return (
      <div>
        <TextTitle>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={operatorNames} />
          <TextItem title='操作' value='未操作' />
        </TextList>
      </div>
    )
  }

  // 当有操作权限时，渲染自定义操作块，否则渲染无权限操作
  renderCurrentStepByFreeWhenAuthority () {
    const { currentStep: d, otherData } = this.state
    if (!d || !d.stepid) {
      return null
    }
    const isOperate = d.operators.some(o => o.id === this.props.userInfo.employeeid)
    if (!isOperate) { // 判断当前操作者是否有操作权限
      return this.noAuthorityrCurrentStep(d)
    }
    return this.props.renderCurrentStep(this.props.form, d, this.renderCurrentStep.bind(this, d), otherData)
  }

  render () {
    const { ready, isError } = this.state
    if (!ready) {
      return null
    }
    if (ready && isError) {
      return (
        <div className={style['result-content']}>
          <Border bottom={false} left={false} right={false} radius={false}>
            <Result
              img={<Icon type='cross-circle-o' className={style['error-icon']} />}
              title='加载失败'
              message='请联系管理员'
            />
          </Border>
        </div>
      )
    }
    return (
      <div className={style.container} id='approve'>
        <Border bottom={false} left={false} right={false} radius={false} />
        {this.renderHistoryList()}
        {this.renderCurrentStepByFreeWhenAuthority()}
        {this.renderOperateBtn()}
      </div>
    )
  }
}

Index.propTypes = {
  flowid: PropTypes.string,
  flowInstanceid: PropTypes.string,
  renderHistory: PropTypes.func,
  renderCurrentStep: PropTypes.func,
  beforeSubmit: PropTypes.func,
  initialCurrentStepFormData: PropTypes.func,
  afterAction: PropTypes.func,
  form: formShape,
  initOtherData: PropTypes.func
}

Index.defaultProps = {
  flowid: '',
  flowInstanceid: '',
  renderHistory: (h, renderFn) => renderFn(h),
  renderCurrentStep: (formRef, currentStep, renderFn, otherData) => renderFn(currentStep),
  beforeSubmit: (instance, data) => Promise.resolve(data), // 提交前，对数据进行操作
  initialCurrentStepFormData: null, // 设置表单初始化数据
  afterAction: (currentInstance, currentAction) => {}, // 操作结束后回调
  form: null,
  initOtherData: (history, currentStep) => Promise.resolve(null) // 在呈批渲染前，初始化其他数据
}

const mapState = mapStateToProps(function (state) {
  return {
    userInfo: state.basic.userInfo
  }
})

const mapDispatch = mapDispatchToProps(function (dispatch) {
  return {

  }
})

export default connect(mapState, mapDispatch)(createForm()(Index))
