import DefaultDesktop from './desktop/DefaultDesktop'
import LeaderDesktop from './desktop/LeaderDesktop'
import BusinessDesktop from './desktop/BusinessDesktop'
import SecretaryGeneralDesktop from './desktop/SecretaryGeneralDesktop'
import DeputySecretaryGeneralDesktop from './desktop/DeputySecretaryGeneralDesktop'
import BookRoomList from './BookRoomList'
import FZBookRoomList from './FZBookRoomList'
import CaseList from './CaseList'
import ApproveList from './ApproveList'
import MailList from './MailList'
import MyCaseList from './MyCaseList'
import MyBookRoomList from './MyBookRoomList'
import MyApproveList from './MyApproveList'
import LetterList from './LetterList'
import Mine from './Mine/index'
import ModifyPassword from './ModifyPassword'
import DocumentExchange from './DocumentExchange'
export default {
  BookRoomList,
  FZBookRoomList,
  CaseList,
  ApproveList,
  MailList,
  DefaultDesktop,
  LeaderDesktop,
  BusinessDesktop,
  SecretaryGeneralDesktop,
  DeputySecretaryGeneralDesktop,
  MyCaseList,
  MyBookRoomList,
  MyApproveList,
  LetterList,
  Mine,
  ModifyPassword,
  DocumentExchange
}
