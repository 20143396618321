import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { TextTitle, TextList, TextItem, FileTitleItem } from '@/components/TextList'
import style from './Step.module.less'

export default class StepOneHistory extends Component {
  state = {
    keyTitleMap: {},
    keyValueMap: {},
    file: [],
    otherfile: []
  }

  // 格式化时间
  formatTime (time) {
    if (time) {
      return moment(time * 1000).format('YYYY-MM-DD HH:mm')
    }
  }

  initKeyTitleMap () {
    const map = {}
    const vMap = {}
    const list = _.get(this.props.action || {}, 'vars', [])
    list.forEach(li => {
      map[li.key] = li.title
      vMap[li.key] = li.value
    })
    this.setState({
      keyTitleMap: map,
      keyValueMap: vMap
    })
  }

  initFile (k, v) {
    if (typeof v === 'string') {
      try {
        this.setState({
          [k]: JSON.parse(v)
        })
      } catch (e) {
        console.error(e)
      }
    } else if (v instanceof Array) {
      this.setState({ [k]: v })
    }
  }

  componentDidUpdate (prev) {
    if (this.props.action !== prev.action) {
      this.initKeyTitleMap()
      this.initFile('file', (this.props.action.varsMap.other_fileid || {}).value)
      this.initFile('otherfile', (this.props.action.varsMap.other_pdf_fileid || {}).value)
    }
  }

  componentDidMount () {
    this.initKeyTitleMap()
    this.initFile('file', (this.props.action.varsMap.other_fileid || {}).value)
    this.initFile('otherfile', (this.props.action.varsMap.other_pdf_fileid || {}).value)
  }

  renderDocs (v) {
    return (
      <div>
        {v.map((li, i) => {
          return (
            <div key={i} className={style.item}>
              <span className={style['item-no']}>{i + 1}</span>
              <span className={style['item-split']}>{li.original_filename}</span>
            </div>
          )
        })}
      </div>
    )
  }

  render () {
    const { keyTitleMap, file, otherfile, keyValueMap } = this.state
    const d = this.props.action
    return (
      <div>
        <TextTitle rightContent={this.formatTime(d.complete_datetime)}>{d.step_name}</TextTitle>
        <TextList>
          <TextItem title='操作者' value={d.operator} />
          <TextItem title='操作' value={d.action_name} />
          <FileTitleItem title={keyTitleMap.other_fileid} value={file} />
          <FileTitleItem title={keyTitleMap.other_pdf_fileid} value={otherfile} />
          <TextItem title={keyTitleMap.document_sealtype} value={keyValueMap.document_sealtype} />
          <TextItem title={d.remark_alias || '备注'} value={d.remark || '无'} />
        </TextList>
      </div>
    )
  }
}

StepOneHistory.propTypes = {
  action: PropTypes.object
}

StepOneHistory.defaultProps = {
  action: {}
}
